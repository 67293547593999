import { BorderBeam } from '@/shared/components/animation/border-beam';
import { CallbackForm } from '@/shared/components/callback-form';
import { CrossIcon } from '@/shared/components/icons/cross-icon';
import { Button } from '@/shared/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/shared/components/ui/card';

interface MarketingPopupProps {
  onCloseClick: () => void;
}

export const MarketingPopup = (props: MarketingPopupProps) => {
  const { onCloseClick } = props;

  return (
    <Card className="fixed bottom-6 right-4 z-50 w-[280px] bg-neutral-100 dark:bg-zinc-800 sm:right-6 sm:min-w-[300px] md:w-full md:max-w-[340px] lg:max-w-[460px]">
      <CardHeader className="relative p-4 pb-4 lg:p-6 lg:pb-4">
        <Button className="absolute right-4 top-4" size="icon-xs" variant="outline" onClick={onCloseClick}>
          <CrossIcon className="h-4 w-4" />
        </Button>

        <CardTitle className="text-lg">&#128640;&nbsp;Урок в&nbsp;подарок&nbsp;&#128640;</CardTitle>

        <CardDescription className="text-xs">
          <sup>*</sup>Для новых учеников
        </CardDescription>
      </CardHeader>

      <CardContent>
        <p className="mb-4">До 10 сентября</p>

        <CallbackForm>
          <Button variant="cta" className="px-6">
            Подробнее
          </Button>
        </CallbackForm>
        <BorderBeam borderWidth={2} className="rounded-lg" />
      </CardContent>
    </Card>
  );
};
